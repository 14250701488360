import React, { useEffect } from 'react';
import * as styles from './certificate.module.less';

const Index = () => {
  useEffect(() => {
    document.title = "珠宝区块链证书";
  }, []);
  return (
    <div className={styles.contant}>
      <img className={styles.img} src="https://wb-static-sh.oss-cn-shanghai.aliyuncs.com/demo/202161-18142.jpeg" alt=""/>
    </div>
  )
}

export default Index;